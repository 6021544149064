import CompleteHomePage from '@fragment/pages/CompleteHomePage';
import CompleteBlogLanding from '@fragment/pages/CompleteBlogLanding';
import CompleteInTheNewsBlogLanding from '@fragment/pages/CompleteInTheNewsBlogLanding';
import CompletePressReleaseBlogLanding from '@fragment/pages/CompletePressReleaseBlogLanding';
import CompleteBlogPost from '@fragment/pages/CompleteBlogPost';
import CompleteGlobalSearchIndex from '@fragment/pages/CompleteGlobalSearchIndex';
import CompleteResourceSearchIndex from '@fragment/pages/CompleteResourceSearchIndex';
import CompleteResourceLandingPage from '@fragment/pages/CompleteResourceLandingPage';
import CompleteResource from '@fragment/pages/CompleteResource';
import CompleteCorePractice from '@fragment/pages/CompleteCorePractice';
import CompleteCorePracticeDomain from '@fragment/pages/CompleteCorePracticeDomain';
import CompleteNewsEventsLanding from '@fragment/pages/CompleteNewsEventsLanding';
import CompleteInTheNewsBlogPost from '@fragment/pages/CompleteInTheNewsBlogPost';
import CompletePressReleaseBlogPost from '@fragment/pages/CompletePressReleaseBlogPost';
import CompleteCareersLandingPage from '@fragment/pages/CompleteCareersLandingPage';
import CompleteJobOpeningLanding from '@fragment/pages/CompleteJobOpeningLanding';
import CompletePartnerJobOpeningLanding from '@fragment/pages/CompletePartnerJobOpeningLanding';
import CompleteMarketingEventsLanding from '@fragment/pages/CompleteMarketingEventsLanding';
import CompleteMarketingEvent from '@fragment/pages/CompleteMarketingEvent';
import CompleteMediaKit from '@fragment/pages/CompleteMediaKit';
import CompletePeople from '@fragment/pages/CompletePeople';
import CompletePerson from '@fragment/pages/CompletePerson';
import CompleteSchoolLanding from '@fragment/pages/CompleteSchoolLanding';
import CompleteSchool from '@fragment/pages/CompleteSchool';
import CompleteHistoryLanding from '@fragment/pages/CompleteHistoryLanding';
import CompletePLConference from '@fragment/pages/CompletePLConference';
import CompletePLCalendarEventsSearchIndex from '@fragment/pages/CompletePLCalendarEventsSearchIndex';
import CompletePLCatalogEntrySearchIndex from '@fragment/pages/CompletePLCatalogEntrySearchIndex';
import CompletePLLanding from '@fragment/pages/CompletePLLanding';
import CompletePLCatalogEntry from '@fragment/pages/CompletePLCatalogEntry';
import CompleteUserRegistrationPage from '@fragment/pages/CompleteUserRegistrationPage';
import CompleteDonationPage from '@fragment/pages/CompleteDonationPage';
import CompleteDistrictPartnershipsPage from '@fragment/pages/CompleteDistrictPartnershipsPage';
import CompleteTermsOfUsePage from '@fragment/pages/CompleteTermsOfUsePage';
import CompleteAnnualReport from '@fragment/pages/CompleteAnnualReport';
import CompleteStudy from '@fragment/pages/CompleteStudy';
import CompleteCustomForm from '@fragment/pages/CompleteCustomForm';
import CompleteAward from '@fragment/pages/CompleteAward';
import CompleteModelOfExcellencePage from '@fragment/pages/CompleteModelOfExcellencePage';
import CompleteCurriculumInformational from '@fragment/pages/CompleteCurriculumInformational';
import CompleteCurriculumGradePage from '@fragment/pages/CompleteCurriculumGradePage';
import CompleteCurriculumModulePage from '@fragment/pages/CompleteCurriculumModulePage';
import CompleteCurriculumUnitPage from '@fragment/pages/CompleteCurriculumUnitPage';
import CompleteCurriculumLessonPage from '@fragment/pages/CompleteCurriculumLessonPage';
import CompleteCurriculumModulesAndLessonsLandingPage from '@fragment/pages/CompleteCurriculumModulesAndLessonsLandingPage';
import CompleteCurriculumProtocolSearchIndex from '@fragment/pages/CompleteCurriculumProtocolSearchIndex';
import CompleteCurriculumProtocolPage from '@fragment/pages/CompleteCurriculumProtocolPage';
import CompleteCurriculumPlanningLandingRequiredTextPage from '@fragment/pages/CompleteCurriculumPlanningLandingRequiredTextPage';
import CompleteCurriculumPlanningLandingRecommendedTextPage from '@fragment/pages/CompleteCurriculumPlanningLandingRecommendedTextPage';
import CompleteCurriculumPlanningLandingStandardPage from '@fragment/pages/CompleteCurriculumPlanningLandingStandardPage';
import CompleteWholeGroupMicrophasePage from './fragments/pages/CompleteWholeGroupMicrophasePage';
import CompleteSmallGroupMicrophasePage from './fragments/pages/CompleteSmallGroupMicrophasePage';
import CompleteWholeGroupLessonPage from './fragments/pages/CompleteWholeGroupLessonPage';
import CompleteSmallGroupCyclePage from './fragments/pages/CompleteSmallGroupCyclePage';
import CompleteContactUsPage from './fragments/pages/CompleteContactUsPage';
import CompleteIndependentStudentActivityPage from './fragments/pages/CompleteIndependentStudentActivityPage';
import CompleteBenchmarkAssessmentPage from './fragments/pages/CompleteBenchmarkAssessmentPage';
import CompleteIndependentStudentActivitySearchIndex from './fragments/pages/CompleteIndependentStudentActivitySearchIndex';
import CompleteDecodableReaderSearchIndex from './fragments/pages/CompleteDecodableReaderSearchIndex';
import CompleteToolSearchIndex from './fragments/pages/CompleteToolSearchIndex';
import CompleteRoutineSearchIndex from './fragments/pages/CompleteRoutineSearchIndex';
import CompleteRoutinePage from './fragments/pages/CompleteRoutinePage';
import CompleteMicrositePage from './fragments/pages/CompleteMicrositePage';
import CompleteMicrositeInformational from './fragments/pages/CompleteMicrositeInformational';

export const pageMap = {
    CompleteHomePage: {
        fragment: CompleteHomePage,
        component: () => import('@page/core/Informational'),
    },
    CompleteBlogLanding: {
        fragment: CompleteBlogLanding,
        component: () => import('@page/core/BlogLanding'),
    },
    CompleteInTheNewsBlogLanding: {
        fragment: CompleteInTheNewsBlogLanding,
        component: () => import('@page/core/BlogLanding'),
    },
    CompletePressReleaseBlogLanding: {
        fragment: CompletePressReleaseBlogLanding,
        component: () => import('@page/core/BlogLanding'),
    },
    CompleteBlogPost: {
        fragment: CompleteBlogPost,
        component: () => import('@page/core/BlogPost'),
    },
    CompleteGlobalSearchIndex: {
        fragment: CompleteGlobalSearchIndex,
        component: () => import('@page/core/Search'),
    },
    CompleteResourceSearchIndex: {
        fragment: CompleteResourceSearchIndex,
        component: () => import('@page/core/ResourceSearchIndex'),
    },
    CompleteResource: {
        fragment: CompleteResource,
        component: () => import('@page/core/Resource'),
    },
    CompleteResourceLandingPage: {
        fragment: CompleteResourceLandingPage,
        component: () => import('@page/core/ResourceLandingPage'),
    },
    CompleteCorePractice: {
        fragment: CompleteCorePractice,
        component: () => import('@page/core/CorePractice'),
    },
    CompleteCorePracticeDomain: {
        fragment: CompleteCorePracticeDomain,
        component: () => import('@page/core/CorePracticeDomain'),
    },
    CompleteNewsEventsLanding: {
        fragment: CompleteNewsEventsLanding,
        component: () => import('@page/core/NewsEventsLanding'),
    },
    CompleteInTheNewsBlogPost: {
        fragment: CompleteInTheNewsBlogPost,
        component: () => import('@page/core/InTheNewsBlogPost'),
    },
    CompletePressReleaseBlogPost: {
        fragment: CompletePressReleaseBlogPost,
        component: () => import('@page/core/PressReleaseBlogPost'),
    },
    CompleteCareersLandingPage: {
        fragment: CompleteCareersLandingPage,
        component: () => import('@page/core/CareersLanding'),
    },
    CompleteJobOpeningLanding: {
        fragment: CompleteJobOpeningLanding,
        component: () => import('@page/core/CareerOpenings'),
    },
    CompletePartnerJobOpeningLanding: {
        fragment: CompletePartnerJobOpeningLanding,
        component: () => import('@page/core/CareerOpenings'),
    },
    CompleteMarketingEventsLanding: {
        fragment: CompleteMarketingEventsLanding,
        component: () => import('@page/core/MarketingEventsLanding'),
    },
    CompleteMarketingEvent: {
        fragment: CompleteMarketingEvent,
        component: () => import('@page/core/MarketingEvent'),
    },
    CompleteMediaKit: {
        fragment: CompleteMediaKit,
        component: () => import('@page/core/MediaKit'),
    },
    CompletePeople: {
        fragment: CompletePeople,
        component: () => import('@page/core/People'),
    },
    CompletePerson: {
        fragment: CompletePerson,
        component: () => import('@page/core/Person'),
    },
    CompleteSchoolLanding: {
        fragment: CompleteSchoolLanding,
        component: () => import('@page/core/SchoolLanding'),
    },
    CompleteSchool: {
        fragment: CompleteSchool,
        component: () => import('@page/core/School'),
    },
    CompleteHistoryLanding: {
        fragment: CompleteHistoryLanding,
        component: () => import('@page/core/HistoryLanding'),
    },
    CompletePLConference: {
        fragment: CompletePLConference,
        component: () => import('@page/core/PLConference'),
    },
    CompletePLCalendarEventsSearchIndex: {
        fragment: CompletePLCalendarEventsSearchIndex,
        component: () => import('@page/core/PLCalendarEventsSearchIndex'),
    },
    CompletePLCatalogEntrySearchIndex: {
        fragment: CompletePLCatalogEntrySearchIndex,
        component: () => import('@page/core/PLCatalogEntrySearchIndex'),
    },
    CompletePLLanding: {
        fragment: CompletePLLanding,
        component: () => import('@page/core/PLLanding'),
    },
    CompletePLCatalogEntry: {
        fragment: CompletePLCatalogEntry,
        component: () => import('@page/core/PLCatalogEntry'),
    },
    CompleteUserRegistrationPage: {
        fragment: CompleteUserRegistrationPage,
        component: () => import('@page/account/UserRegistration'),
    },
    CompleteDonationPage: {
        fragment: CompleteDonationPage,
        component: () => import('@page/core/DonationPage'),
    },
    CompleteDistrictPartnershipsPage: {
        fragment: CompleteDistrictPartnershipsPage,
        component: () => import('@page/core/DistrictPartnershipsPage'),
    },
    CompleteTermsOfUsePage: {
        fragment: CompleteTermsOfUsePage,
        component: () => import('@page/core/Informational'),
    },
    CompleteAnnualReport: {
        fragment: CompleteAnnualReport,
        component: () => import('@page/core/AnnualReport'),
    },
    CompleteStudy: {
        fragment: CompleteStudy,
        component: () => import('@page/core/Study'),
    },
    CompleteCustomForm: {
        fragment: CompleteCustomForm,
        component: () => import('@page/core/CustomForm'),
    },
    CompleteAward: {
        fragment: CompleteAward,
        component: () => import('@page/core/Award'),
    },
    CompleteModelOfExcellencePage: {
        fragment: CompleteModelOfExcellencePage,
        component: () => import('@page/core/ModelsOfExcellence'),
    },
    CompleteCurriculumInformational: {
        fragment: CompleteCurriculumInformational,
        component: () => import('@page/curriculum/common/LandingPage'),
    },
    CompleteCurriculumGradePage: {
        fragment: CompleteCurriculumGradePage,
        component: () => import('@page/curriculum/language-arts/landings/GradePage.vue'),
    },
    CompleteCurriculumModulePage: {
        fragment: CompleteCurriculumModulePage,
        component: () => import('@page/curriculum/language-arts/module-lessons/ModulePage'),
    },
    CompleteCurriculumUnitPage: {
        fragment: CompleteCurriculumUnitPage,
        component: () => import('@page/curriculum/language-arts/module-lessons/UnitPage'),
    },
    CompleteCurriculumLessonPage: {
        fragment: CompleteCurriculumLessonPage,
        component: () => import('@page/curriculum/language-arts/module-lessons/LessonPage'),
    },
    CompleteCurriculumModulesAndLessonsLandingPage: {
        fragment: CompleteCurriculumModulesAndLessonsLandingPage,
        component: () => import('@page/curriculum/language-arts/landings/ModulesAndLessonsLandingPage'),
    },
    CompleteCurriculumProtocolSearchIndex: {
        fragment: CompleteCurriculumProtocolSearchIndex,
        component: () => import('@page/curriculum/language-arts/planning/ProtocolSearchIndex'),
    },
    CompleteCurriculumProtocolPage: {
        fragment: CompleteCurriculumProtocolPage,
        component: () => import('@page/curriculum/language-arts/planning/ProtocolPage'),
    },
    CompleteCurriculumPlanningLandingRequiredTextPage: {
        fragment: CompleteCurriculumPlanningLandingRequiredTextPage,
        component: () => import('@page/curriculum/language-arts/planning/PlanningTextsLandingPage'),
    },
    CompleteCurriculumPlanningLandingRecommendedTextPage: {
        fragment: CompleteCurriculumPlanningLandingRecommendedTextPage,
        component: () => import('@page/curriculum/language-arts/planning/PlanningTextsLandingPage'),
    },
    CompleteCurriculumPlanningLandingStandardPage: {
        fragment: CompleteCurriculumPlanningLandingStandardPage,
        component: () => import('@page/curriculum/language-arts/planning/PlanningTextsLandingPage'),
    },
    CompleteWholeGroupMicrophasePage: {
        fragment: CompleteWholeGroupMicrophasePage,
        component: () => import('@page/curriculum/language-arts/skills-block/WholeGroupMicrophasePage'),
    },
    CompleteWholeGroupLessonPage: {
        fragment: CompleteWholeGroupLessonPage,
        component: () => import('@page/curriculum/language-arts/skills-block/WholeGroupLessonPage'),
    },
    CompleteSmallGroupMicrophasePage: {
        fragment: CompleteSmallGroupMicrophasePage,
        component: () => import('@page/curriculum/language-arts/skills-block/SmallGroupMicrophasePage'),
    },
    CompleteSmallGroupCyclePage: {
        fragment: CompleteSmallGroupCyclePage,
        component: () => import('@page/curriculum/language-arts/skills-block/SmallGroupCyclePage'),
    },
    CompleteContactUsPage: {
        fragment: CompleteContactUsPage,
        component: () => import('@page/core/Informational'),
    },
    CompleteIndependentStudentActivityPage: {
        fragment: CompleteIndependentStudentActivityPage,
        component: () => import('@page/curriculum/language-arts/skills-block/IndependentStudentActivityPage'),
    },
    CompleteBenchmarkAssessmentPage: {
        fragment: CompleteBenchmarkAssessmentPage,
        component: () => import('@page/curriculum/language-arts/planning/BenchmarkAssessmentPage'),
    },
    CompleteIndependentStudentActivitySearchIndex: {
        fragment: CompleteIndependentStudentActivitySearchIndex,
        component: () => import('@page/curriculum/language-arts/skills-block/IndependentStudentActivitySearchIndex'),
    },
    CompleteDecodableReaderSearchIndex: {
        fragment: CompleteDecodableReaderSearchIndex,
        component: () => import('@page/curriculum/language-arts/skills-block/DecodableReaderSearchIndex'),
    },
    CompleteToolSearchIndex: {
        fragment: CompleteToolSearchIndex,
        component: () => import('@page/curriculum/language-arts/skills-block/ToolSearchIndex'),
    },
    CompleteRoutinePage: {
        fragment: CompleteRoutinePage,
        component: () => import('@page/curriculum/language-arts/skills-block/RoutinePage'),
    },
    CompleteRoutineSearchIndex: {
        fragment: CompleteRoutineSearchIndex,
        component: () => import('@page/curriculum/language-arts/skills-block/RoutineSearchIndex'),
    },
    CompleteMicrositePage: {
        fragment: CompleteMicrositePage,
        component: () => import('@page/core/MicrositePage'),
    },
    CompleteMicrositeInformational: {
        fragment: CompleteMicrositeInformational,
        component: () => import('@page/core/MicrositeInformational'),
    },
};
